import React from 'react'
import { Link } from 'gatsby'
import { window } from 'browser-monads'
import LandingPageLowLevel from 'components/landing-page-complete-low-level'
import CtaRow from 'components/cta-row'
import PricingColumn from 'components/pricing-column'
import TrustboxMicroCombo from 'components/trustpilot-micro-combo'
import TrustboxSlider from 'components/trustpilot-slider'
import { moneybird, exact, twinfield, yuki_alt } from 'images/koppelingen'
import Helmet from 'react-helmet'

import {pricingCheck, salarisHrIllustraion, hrIllustration} from 'images'

import * as images from 'images/home'
import {
  avy_1,
  avy_2,
  avy_3,
  avy_5,
  avy_logo,
  woodwatch_1,
  woodwatch_2,
  woodwatch_3,
} from 'images/klanten'
import Layout from '../../layouts'
import Overlay from '../../components/overlay'

import config from '../../config'
const { appUrl, signupRoute } = config

import {
  direct,
  hand,
  kosten,
  vragen,
  steps,
  one,
  two,
  three,
  four,
  screenshot,
  confetti,
} from 'images/product/salaris'

import { illuHr3, illuHr2 } from 'images/product/hr'

const dummyTable = {
  packageName : 'Salaris & HR',
  packageDescription : 'De complete oplossing',
  title: 'Inclusief:',
  price: '39,-',
  button: 'primary',
  prijsWerknemer: '8,-',
  features: [
    {description: '100% online salarisadministratie', active: true},
    {description: 'Alle salarisdocumenten (loonstroken, jaaropgave etc.)', active: true},
    {description: 'App voor werknemers', active: true},
    {description: 'Koppelingen', active: true},
    {description: 'Verlof & verzuim', active: true, type: 'HR'},
    {description: 'Declaraties', active: true, type: 'HR'},
    {description: 'Contract templates', active: true, type: 'HR'},
    {description: 'Digitaal ondertekenen', active: false, type: 'ADD-ON'},
    {description: 'Urenregistratie', active: false, type: 'ADD-ON'}
  ]
}

const dummyTable2 = {
  packageName : 'Salaris',
  packageDescription : 'De basis benodigdheden',
  title: 'Inclusief:',
  price: '29,-',
  button: 'primary',
  prijsWerknemer: '6,50',
  features: [
    {description: '100% online salarisadministratie', active: true},
    {description: 'Alle salarisdocumenten (loonstroken, jaaropgave etc.)', active: true},
    {description: 'App voor werknemers', active: true},
    {description: 'Koppelingen', active: true},
    {description: 'Verlof & verzuim', active: false},
    {description: 'Declaraties', active: false},
    {description: 'Contract templates', active: false},
    {description: 'Digitaal ondertekenen', active: false, type: 'ADD-ON'},
    {description: 'Urenregistratie', active: false, type: 'ADD-ON'}
  ]
}

const {
  homeHero,
  employesUI,
  homeCtaIllu,
  homeCtaIlluNew,
  logos: { woodwatch, petossi, storageShare, codaisseur },
  illus: { eenvoudig, hrzaken, verlof, koppelingen, bespaar, salaris, hr, declaraties },
  icons: { iconSalaris, iconDeclaraties, iconVerlof, iconHR },
  avatars: { marieke, peter },
} = images



const benefits = [
  {
    title: 'Eenvoudige online verloning',
    description: 'Via een eenvoudig stappenplan kun je de verloning doen. Je geeft snel de gewerkte uren, verlof en vergoedingen door. Daarna kun je je werknemers betalen via een betaalbestand en verstuur je de loonstroken.',
    image: two
  },
  {
    title: 'Automatische loonaangifte',
    description: 'Wanneer je de verloning doet, gebeurt de loonaangifte geheel automatisch. En wij zorgen ervoor dat je voldoet aan alle regelgeving zodat je hier zelf niet meer naar om hoeft te kijken.',
    image: illuHr2
  },
  {
    title: 'Salaris, HR & verlof op één plek',
    description: 'Naast salarisverwerking kun je ook al je HR-zaken bijhouden in Employes. We bieden een uitgebreide verlofmodule voor vakantie en ziekte. Verder kun je automatisch arbeidscontracten maken en zitten er uitgebreide rapporten in Employes.',
    image: illuHr3
  }

]



class WelkomPage extends React.Component {



  constructor(props) {
    super(props)

    this.state = {
      email: '',
    }
  }


  changeHandler = event => {
    this.setState({
      email: event.target.value,
    })
  }

  submitFormHandler = event => {
    event.preventDefault()
    window.location = `${appUrl}${signupRoute}?email=${this.state.email}`
    window.analytics.track('User entered email on homepage', {
      email: this.state.email,
    })
  }

  render() {

    window.analytics.track('Viewed Adwords landingspage')

    return (
      <Layout location={this.props.location} landingPage={true}>
        <main className="home animated landing-page fadeInPage">

          <Helmet>
            <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
            <meta name="robots" content="noindex,follow" />
          </Helmet>

          <header className="header padding-l">
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-7 header-text">
                  <h1>Eenvoudige online payroll software</h1>
                  <p className="streamer margin-m-bottom">
                    Snel en eenvoudig alles geregeld. Je salarisadministratie doen was nog nooit zo makkelijk. Bespaar tijd en kosten.
                  </p>

                  <form className="sign-up" onSubmit={this.submitFormHandler}>
                    <input
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.changeHandler}
                      placeholder="Je zakelijke e-mailadres"
                      className="large combined"
                      required
                    />
                    <button className="btn primary lg combined">
                      Probeer nu
                      <span className="hide-mobile">&nbsp;gratis</span>
                    </button>
                  </form>

                  <br />
                  <p className="small sky-dark">
                    Geheel vrijblijvend{' '}
                    <span className="hide-mobile">
                      | Geen salariskennis nodig{' '}
                    </span>{' '}
                    | Eenvoudig starten
                  </p>
                </div>

                <div className="hero-illustration">
                  <img src={homeHero} alt="Online salarisadministratie" />
                </div>
              </div>
            </div>


            <div className="container-lg margin-l-top">
              <TrustboxMicroCombo></TrustboxMicroCombo>
            </div>

          </header>

          <section className="overview padding-xl">
            <div className="container-sm">
              <div className="grid center text-center yg">
                <div className="col-12 margin-xl-bottom">
                  <h2>Voordelen van Employes</h2>
                  <p className="streamer center">Employes biedt je een laagdrempelige online payroll oplossing. Dat scheelt tijd en bespaart kosten.</p>

                </div>

                <div className="grid between features">
                  <div className="col-3 feature">
                    <div className="feature-icon">
                      <img src={direct} className="benefit-icon" alt="icoon direct controle" />
                    </div>
                    <h5>Gebruiksvriendelijk systeem</h5>

                  </div>
                  <div className="col-3 feature">
                    <div className="feature-icon">
                      <img src={hand} className="benefit-icon" alt="icoon hand inzicht" />
                    </div>
                    <h5>Direct van start</h5>

                  </div>
                  <div className="col-3 feature">
                    <div className="feature-icon">
                      <img src={kosten} className="benefit-icon" alt="icoon kosten inzicht" />
                    </div>
                    <h5>Geen verborgen kosten</h5>

                  </div>
                  <div className="col-3 feature">
                    <div className="feature-icon">
                      <img src={vragen} className="benefit-icon" alt="icoon vragen support" />
                    </div>
                    <h5>Snelle klantenservice</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <LandingPageLowLevel></LandingPageLowLevel>

          <div className="margin-l-top">
            {benefits.map((benefit, idx) => (
              <section key={`${benefit}-${idx}`}>
                <div className="container-sm padding-m-top padding-m-bottom">
                  <div className="grid">
                    <div className="col-6 ">
                      <div>
                        <img src={benefit.image} className="benefit-illustration" alt="Illustration" ref="illuTwo" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div>
                        <h3>{benefit.title}</h3>
                        <p>{benefit.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ))}
          </div>

          <div className="logo-grid block">
            <div className="container-sm margin-s-bottom margin-l-top">
              <div className="grid yg align-middle logos">
                {[moneybird, exact, twinfield, yuki_alt].map((img, idx) => (
                  <div className="col-3 align-middle  no-mar" key={idx}>
                    <div className={`logo ${idx === 0 ? 'moneybird' : idx === 1 ? 'exact' : idx === 2 ? 'twinfield' : idx === 3 ? 'yuki' : ''}`}>
                      <img src={img} alt="logos boekhoudpakketten"/>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
          <div className="container-sm block padding-xl-bottom">
            <div className="grid center text-center yg align-middle">
              <p className="hide-mobile">Je kunt Employes koppelen aan verschillende boekhoudprogramma's.</p>
            </div>
          </div>


          <section className="overview padding-l">
            <div className="container-sm">
              <div className="grid center text-center yg">
                <div className="col-12">
                  <h3>Kies het pakket dat bij je past</h3>
                </div>
              </div>
            </div>
          </section>

          <div className="pricing-columns">
            <div className="backdrop"></div>
            <div className="container-sm ">
              <div className="grid yg padding-m-bottom">
                <div className="col-6">
                  <PricingColumn data={dummyTable2} className="blue" headerIllustration={hrIllustration}/>
                </div>
                <div className="col-6">
                  <PricingColumn data={dummyTable} className="blue" headerIllustration={salarisHrIllustraion}/>
                </div>
              </div>

              <div className="grid yg center text-center streamer">
                <div className="col-10">
                  <p>Heb je een holding of een bedrijf met 1 werknemer dan kun je ook het <Link to="/tarieven/dga-holding/" className="link inline">DGA-pakket</Link> kiezen van € 14,95 (excl. btw)</p>
                </div>
              </div>
            </div>
          </div>

          <CtaRow xtraSmall freeIllu={homeCtaIlluNew}/>

          <section className="padding-xl">
            <div className="container-lg">
              <div className="grid center text-center yg">
                <div className="col-12">
                  <TrustboxSlider></TrustboxSlider>
                </div>
              </div>
            </div>
          </section>

        </main>

      </Layout>
    )
  }
}

export default WelkomPage
